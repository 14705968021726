import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { Blocks } from "../gutenberg/blocks";
import { StyledPage } from "./page.styles";
import { getFeaturedImage } from "../helpers/image";
import { PageContextProvider } from "../global/page.context";
import { PageQueryQuery } from '../../gatsby-graphql';
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { siteTheme } from "../global/theme.styles";

export interface IPageProps {
  data: PageQueryQuery;
}

const PageTemplate:React.FC<IPageProps> = props => {
  const page = props.data.wpPage;
  const theme = siteTheme;

  return (
    <StyledPage className="template template--page">
      {page &&
        <>
          <SEO
            title={page.title}
            image={page.featuredImage && page.featuredImage.node && getFeaturedImage(page.featuredImage.node as FileNode)}
          />
          <PageContextProvider>
            {page.blocks && <Blocks blocks={page.blocks} theme={theme} />}
          </PageContextProvider>
        </>
      }
    </StyledPage>
  )
}
export default PageTemplate;

export const query = graphql`
  query PageQuery($id: String) {
      wpPage(id: { eq: $id } ) {
        title
        featuredImage {
          node {
            ...ImageFragment
          }
        }
        blocks {
          __typename
          ...BlocksFragment
        }
      }
  }
`;
