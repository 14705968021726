import React, { useCallback } from 'react';

export type IPageState = Partial<ReturnType<typeof usePageState>>;

export type IPageContext = IPageState;

export const PageContext = React.createContext<IPageContext | null>(
null
);

export type IComponentNestedOrder = (number | IComponentNestedOrder)[];


export type PageClickCallback = (target?: HTMLElement) => void;

  
export const usePageState = () => {
    const [autoRatioImages, setAutoRatioImages] = React.useState<IComponentNestedOrder>([]);

    const addAutoRatioImage = useCallback(
        (newImageOrder: IComponentNestedOrder) => {
            
            !autoRatioImages.indexOf(newImageOrder) && setAutoRatioImages([...autoRatioImages, newImageOrder]);
            // const newIndex = autoRatioImages.reduce((previousValue: IComponentNestedOrder, currentValue: IComponentNestedOrder) => {
            //     if (typeof previousValue === 'number') {

            //     }
            //     // return accumulator + currentValue
            // }, 0);
            return autoRatioImages.sort().indexOf(newImageOrder);
        },
        [autoRatioImages, setAutoRatioImages]
    );
  
    return {
      autoRatioImages,
      addAutoRatioImage
    };
};


export const usePageContext = () => {
    const pageContext = React.useContext(PageContext);

    if (!pageContext) {
        throw new Error(
        'PageMeta Context used outside of PageMetaContext.Provider'
        );
    }

    return pageContext;
};

export interface IPageProviderProps extends IPageState { };

export const PageContextProvider: React.FC<IPageProviderProps> = props => {
    const pageContext = usePageState();

    return (
        <PageContext.Provider value={pageContext}>
            {props.children}
        </PageContext.Provider>
    );
};
  